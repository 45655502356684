$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

.footer{
    margin-top: 128px;
    width:100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 58px;
    padding-bottom: 32px;
    overflow: hidden;
    padding-left: 115px;
    padding-right: 115px;
    .footer-main{
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr; 

        @media (max-width:1056px){
            grid-template-columns: 1fr;
        }

        
        & .footer-flick{
            & .footer-flick-img{
                margin-bottom: 20px;
            }
            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.2px;
                color: #7283A4;
                margin-bottom: 52px;
            }
            & .footer-download{
                display: flex;
                gap: 15px;

                @media (max-width:760px){
                    & .download-link{
                        width: 100px;
                    }
                }
            }

            & .ndpr{
                margin-top: 46px;
               .ndpr_text{
                color: #054D2B;
                font-family: Circular Std;
                font-size: 14px;
                font-style: normal;
                font-weight: 900;
                line-height: 140%; /* 19.6px */
                letter-spacing: 0.135px;
               } 
               .ndpr_img{
                display: flex;
                gap: 16px;
                align-items: flex-start;
               }
            }
        }

        & .footer-widget {
           width: 100%;
            .widget-title {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.2px;
                color: #259792;
                margin-bottom: 30px;
            }

            & .address{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap:9px;
                margin-bottom: 25px;

                & p{
                    font-weight: 450;
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: 0.2px;
                    color: #303C53;
                }
            }
            
        }
    }
}

.footer-menu-link {
    & h6,span{
        color: #303C53;
        margin-bottom: 25px;
    }
    
    li {
        margin-bottom: 25px;
        a {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.2px;
            color: #303C53;
            @media #{$md-layout} {
                font-size: 16px;
            }
            &:hover {
                color: var(--color-link);
            }
        }
    }

   
}
.footer-line{
    width: 100%;
    border: 1px solid #EEEEEE;
    margin-top: 30px;
    margin-bottom: 22px;
}

.footer-aside{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:1056px){
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    & .copywrite{
        padding-top: 10px;
        color: var(--Flick-Subtext, #8C8F97);
        font-family: Circular Std;
        font-size: 10px;
        font-style: normal;
        font-weight: 450;
        line-height: 140%; /* 16.8px */
        letter-spacing: 0.2px;
        
    }
    & .footer-action{
        display: flex;
        align-items: center;

        @media (max-width:535px){
            flex-direction: column-reverse;
            gap:0.6rem;
            align-items: flex-start;
        }
        .foot-action-btn{
            display: flex;
            align-items: center;
            gap:0.5rem;
            .unsubscribe{
                background: #EAF8F8;
                border-radius: 20px;
                font-style: normal;
                font-weight: 450;
                font-size: 10px;
                line-height: 110%;
                color: #2EBDB6;   
                padding: 6px;
                
            }
            .delete{    
                display: inline-block;
                padding: 6px;
                background: rgba(236, 70, 76, 0.07);
                border-radius: 20px;
                margin-left: 13px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                font-weight: 450;
                font-size: 10px;
                line-height: 110%;
                color: #ED1C24;  
                @media (max-width:535px){
                    margin-left: 0;
                } 
                
            }
        }
    }
        
   
}

.footer-social-link {
    display: flex;
    gap: 30px;
    align-items: center;
    
    li {
        a {
            color: var(--color-gray-3);
            font-size: 24px;
            padding: 0 5px 5px 5px;
            @media #{$md-layout} {
                font-size: 20px;
            }
            &:hover {
                color: var(--color-link);
            }
        }
      
    }
}