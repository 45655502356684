.landing-hero {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;

  .landing-wrapper {
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 880px) {
      flex-direction: column;
    }
    .hero-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: 1126px) {
        width: 60%;
      }
      @media (max-width: 880px) {
        width: 100%;
      }
      .hero-left-top {
        border-radius: 100px;
        background: #f6efef;
        padding: 10px;
        color: var(--flick-secondary-5, #151f32);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 32px;
        width: fit-content;
      }
      .hero-left-text {
        color: var(--flick-secondary-5, #151f32);
        text-align: center;
        font-family: Fraunces;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1px;
        width: 35%;
        @media (max-width: 1126px) {
          font-size: 30px;
        }
        & span {
          color: #259792;
          font-style: italic;
        }
      }
      .desc-landing {
        color: var(--Flick-Subtext, #8c8f97);
        font-size: 16px;
        font-style: normal;
        text-align: center;
        font-weight: 450;
        line-height: 30px;
        margin-top: 24px;
        // width: 60%;
      }
      .hero-btn {
        display: flex;
        align-items: center;
        margin-top: 32px;
        gap: 16px;
        @media (max-width: 580px) {
          gap: 0;
          width: fit-content;
        }
        .arrow-btn {
          display: inline-block;
          &:hover {
            transform: scale(1.25);
          }
        }
        .landing-download-btn {
          &:hover {
            transform: scale(1.04);
            cursor: pointer;
          }
        }
      }
    }
    .hero_right {
      //    position: relative;

      .hero_middle {
        // position: absolute;
        /* Move the left side of the element to the middle of the container */
        /* Move the left side of the element to the middle of the container */
        // top:200px;
        // right: 200px;
      }
    }
  }
}

// partners
.partners {
  width: 100%;
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .partner-wrapper {
    width: 52%;
    border-radius: 16px;
    border: 1px solid var(--Flick-Stroke, #eaecf0);
    display: flex;
    align-items: center;
    justify-content: center;

    .partner-inner {
      width: 91.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 32px;
      .partner-title {
        color: #8c8f97;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 30px;
      }
      .partner-slider {
        display: flex;
        align-items: center;
        gap: 64px;
        margin-top: 16px;
        margin-bottom: 28px;
      }

      .partner-ruler {
        background: #eaecf0;
        width: 100%;
        border: 1px solid #eaecf0;
      }
      .partner-license {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 34px;
      }
    }
  }
}

// main

.landing_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .main_wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    .flick-product-content {
      margin-top: 56px;
      display: flex;
      width: 100%;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 580px) {
        margin-top: 20px;
      }
      .flick-product {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 580px) {
          width: 100%;
        }
        .flick-product-p {
          margin: 0;
          padding: 0;
          color: #151f32;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.28px;
          border-radius: 100px;
          background: #f6efef;
          width: fit-content;
          padding: 10px;
          margin-bottom: 26px;
        }
        .flick-product-h {
          margin: 0;
          padding: 0;
          color: #000;
          text-align: center;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 55px;
        }

        .flick-product-btn {
          display: flex;
          border-radius: 60px;
          background: #f2f5f7;
          align-items: center;
          margin-top: 32px;
          @media (max-width: 580px) {
            margin-bottom: 2rem;
          }
          .flick-product-btn1 {
            margin: 0;
            padding: 0;
            display: flex;
            padding: 10px 14px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 116px;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;

            &.active {
              border-radius: 60px;
              background: var(--flick-primary-6, #259792);
              display: flex;
              width: 116px;
              padding: 10px 14px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              color: #fff;
            }
          }
        }
      }
    }

    .landing-getstarted {
      width: 100%;
      margin-top: 240px;

      @media (max-width: 580px) {
        margin-top: 3rem;
      }

      .getstarted-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 64px;
        @media (max-width: 580px) {
          flex-direction: column;
        }
        .getstarted-content-h {
          color: var(--flick-secondary-5, #151f32);
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 50px;
          @media (max-width: 580px) {
            width: 100%;
          }
        }
        .getstarted-content-p {
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          line-height: 25px;
          width: 33%;
          @media (max-width: 580px) {
            width: 100%;
          }
        }
      }
    }
  }

  .companies {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 96px 0;
    margin-top: 91.7px;
    .companies-text {
      color: var(--colors-text-text-tertiary-600, #475467);
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .companies-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }

  .why-flick-loan {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 96px 0 96px 112px;
    .why-fl {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .flick-loan {
        color: var(--flick-primary-5, #2ebdb6);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .credit-your {
        color: var(--colors-text-text-primary-900, #101828);
        font-family: Fraunces;
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
      .loan-services {
        color: var(--colors-text-text-tertiary-600, #475467);
        font-size: 20px;
        font-weight: 450;
        line-height: 30px;
      }
    }

    .benefits {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;
      flex-wrap: wrap;
      .benefitss {
        display: flex;
        width: 288.5px;
        padding: 24px 24px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: var(--radius-none, 0px);
        background: var(--colors-background-bg-secondary, #f9fafb);

        .benefit-title {
          color: var(--colors-text-text-primary-900, #101828);
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-top: 64px;
        }
        .benefit {
          color: var(--colors-text-text-tertiary-600, #475467);
          font-size: 16px;
          font-weight: 450;
          line-height: 24px;
        }
      }
    }

    .loan-product {
      display: flex;
      gap: 64px;
      .lo-product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
        margin-top: 10px;
        .product-loan {
          width: 85%;
          display: flex;
          align-items: flex-start;
          gap: 16px;
          .product-desc {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .product-title {
              color: var(--colors-text-text-primary-900, #101828);
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
            }
            .product-pro {
              color: var(--colors-text-text-tertiary-600, #475467);
              font-size: 16px;
              font-weight: 450;
              line-height: 24px;
            }
            .learn-more {
              color: var(--flick-primary-5, #2ebdb6);
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .data-tools {
    width: 100%;
    background: #111928;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 168px;
    @media (max-width: 580px) {
      margin-top: 3rem;
    }
    .data-tools-wrapper {
      width: 88%;
      margin-top: 128px;

      @media (max-width: 580px) {
        margin-top: 3rem;
      }
      .data-tool-content {
        width: 53%;
        margin: 0 auto;
        margin-bottom: 64px;
        .usecase {
          color: #fff;

          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.28px;
          border-radius: 100px;

          background: var(--flick-primary-5, #2ebdb6);
          padding: 10px;
          margin-bottom: 42px;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }
        .finance-title {
          color: #fff;
          text-align: center;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: 55px;
          margin-bottom: 16px;
        }
        .finance-desc {
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          line-height: 25px;
        }
      }

      .data-tool-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 42px;
        padding-bottom: 92px;
        @media (max-width: 580px) {
          grid-template-columns: 1fr;
          gap: 1.5rem;
        }
      }
    }
  }

  .why-flick {
    width: 100%;
    margin: 0 auto;
    display: flex;
    margin-top: 184px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 580px) {
      margin-top: 3rem;
    }

    .banks_why {
      height: 400px;
      width: 100%;
      position: absolute;
      background: transparent;
      border: none;
      top: 0;
      z-index: 5;

      @media (max-width: 580px) {
        display: none;
      }
      .gtb {
        position: absolute;
        left: 84px;
        top: 49;
      }
      .uba {
        position: absolute;
        left: 31px;
        bottom: 33px;
      }
      .kuda {
        position: absolute;
        left: 361px;
        top: 138px;
      }
      .access {
        position: absolute;
        left: 571px;
        top: 332px;
      }
      .fcmb {
        position: absolute;
        right: 503px;
        top: 393px;
      }
      .circle {
        position: absolute;
        right: 409px;
        top: 99px;
      }
      .unknown {
        position: absolute;
        right: 283px;
        top: 198px;
      }
      .unknown2 {
        position: absolute;
        right: 0;
        top: 99px;
      }
      .firstBank {
        position: absolute;
        right: 57px;
        top: 331px;
      }
    }
    .why-wrapper {
      width: 88%;
      margin-top: 128px;
      .why-content {
        width: 44%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-bottom: 64px;
        .why-subtitle {
          margin: 0 auto;
          display: flex;
          width: 120px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: #f6efef;
          color: var(--flick-secondary-5, #151f32);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        .why-title {
          color: #000;
          text-align: center;
          font-family: Circular Std;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: 55px; /* 152.778% */
        }
      }
      .why-img {
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        gap: 174px;
        @media (max-width: 580px) {
          flex-direction: column;
          position: relative;
        }
        .why-right {
          display: flex;
          flex-direction: column;
          width: 34%;
          margin-top: 180px;
          @media (max-width: 580px) {
            width: 100%;
            top: 600px;
            margin-top: 0;
            position: absolute;
          }
        }
      }
    }
  }
}

.flick-product-items {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 64px;
  gap: 50px;
  overflow: hidden;

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
    margin-top: 10px;
  }

  &.selected {
  }
  .flick-product-item {
    display: flex;
    gap: 24px;
    align-items: flex-start;

    .flick-product-item-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .product-checkout {
        color: var(--flick-secondary-5, #151f32);
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .checkout-desc {
        color: var(--Flick-Subtext, #8c8f97);
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.1px;
      }
    }
  }
}

//

.data-tool-item {
  border-radius: 10px;
  background: #18202f;
  padding: 32px 37px 42px 32px;
  box-sizing: border-box;

  &:hover {
    background: #18202f;
  }
  & img {
    margin-bottom: 94px;
  }
  .data-subtitle {
    color: #8c8f97;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .data-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  .data-desc {
    color: #8c8f97;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0.1px;
  }
}

// why flick

.why-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 33px;
  .why-item-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .why_num {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--flick-secondary-5, #151f32);
      text-align: center;
      font-family: Circular Std;
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      background: #eaecf0;
      width: 36px;
      height: 36px;
      border-radius: 50%;

      &.active {
        background: #259792;
        color: #fff;
      }
    }
    & span {
      border: 1px solid #eaecf0;
      height: 150px;
    }
  }
  .why-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .why-item {
      .why-title {
        color: var(--flick-secondary-5, #151f32);
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .why-desc {
        color: var(--Subtext-2, #606b81);
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 25px; /* 178.571% */
        letter-spacing: 0.1px;
      }
    }
  }
}

// start building

.start_building {
  background: url("../../assets/landingBannerBg.svg") no-repeat center
    center/cover;
  position: relative;
  border-radius: 20px;
  width: 88%;
  margin-top: 53px;
  padding: 64px;
  overflow: hidden;
  @media (max-width: 580px) {
    margin-top: 480px;
  }
  .avater_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 580px) {
      margin-top: 3rem;
      position: relative;
      z-index: 2;
      // margin-left: 2rem;
      width: 80%;
    }

    .avater_desc {
      color: rgba(247, 253, 252, 0.9);
      font-size: 20px;
      font-style: normal;
      font-weight: 450;
      line-height: 142.5%; /* 28.5px */
    }
    .avater_title {
      color: #fff;
      text-align: center;
      font-family: Fraunces;
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.72px;
      margin-bottom: 20px;
      & span {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 110%; /* 44px */
      }
    }
    .enter-email {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      width: 50%;
      margin-top: 32px;
      .input-email {
        width: 100%;
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--colors-border-border-primary, #d0d5dd);
        background: var(--colors-background-bg-primary, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      .avater_btn {
        width: 30%;
        border-radius: 6px;
        background: #fff;
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--flick-primary-6, #259792);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .we-care {
      width: 50%;
      margin-top: 6px;
      color: #fff;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px; 
    }
  }
}
// .data-tool-item:last-child {
//     grid-column: span 2; /* This will make the last item take 2 columns */
// }
