.landing{
    width: 100%;
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
}
.hero-landing{
    width:100%;
    display: flex;
    
    justify-content: center;
    align-items: center;
    .hero-landing-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // @media (max-width:1300px){
        //     & .landing-img{
        //         margin-left:0.5rem;
        //     }
            
        // }
        @media (max-width:1280px){
            & .landing-img{
                margin-left: 1rem;
            }
            
        }
        @media (max-width:1150px){
            & .landing-img{
                width: 50%;
                
            }
            
        }
        @media (max-width:1000px){
            & .landing-img{
                width: 60%;
                
            }
            
        }
        
        @media (max-width:930px){
            flex-direction: column;
            align-items: flex-start;
            gap:2rem;

            & .landing-img{
                display: none;
            }
        }
        
        .hero-content{
            width:53%;
            @media (max-width:1000px){
                width: 100%;
            }
            
            & h1{
                font-style: normal;
                font-weight: 900;
                font-size: 70px;
                line-height: 79px;
                color: #151F32;
                margin-bottom: 23px;
                @media (max-width:1300px){
                    font-size: 65px;
                }
                @media (max-width:1120px){
                    font-size: 3rem ;
                    line-height: 60px;
                }
                @media (max-width:767px){
                    font-size: 3rem;
                }
                @media (max-width:500px){
                    font-size: 2rem;
                    line-height: 60px;
                }
                @media (max-width:380px){
                    line-height: 50px;     
                }
                & .easy-bg{
                    display: inline-block;
                    background: url(../../assets/easyBg.svg) no-repeat center center/cover;
                    padding-left:8px;
                    padding-right: 18px;
                    padding-bottom: 10px;
                    color: #FFFFFF;
                    @media (max-width:1400px){
                        background-size: 165px ;
                        
                    }
                    @media (max-width:1120px){
                        background-size: 120px ;
                        
                    }
                    @media (max-width:767px){
                        background-size: 115px;
                    }
                }
                
            }
            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.2px;
                color: #7283A4;
                margin-bottom: 64px;
                width: 520px;

                @media(max-width:572px){
                    width: 100%;
                }
                @media (max-width:380px){
                    font-size: 1rem;     
                }
            }
            & .landing-button{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap:16px;
                @media (max-width:500px){
                    flex-direction: column;
                }
            }
        }

        
    }
}

.landing-main{
    max-width:1109px;
    width:77%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
}
.hero-landing-main{
    margin-left: auto;
    margin-right: auto;
}
.landing-security{
    margin-top: 188px;
    width: 73%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width:1300px){
        width: 100%;
        margin-top: 7rem;
    }

    & .security-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:100%;

        @media (max-width:767px){
            .security-img{
                display: none;
            }
        }
        & .security-content{
            display: flex;
            flex-direction: column;
            width:100%;
            margin-left: 32px;
            @media (max-width:767px){
                margin-left: 0px;
                
            }
            & h1{
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 46px;
                color: #111928;

                @media (max-width:767px){
                    font-size: 2rem;
                }
                @media (max-width:550px){
                    font-size: 1.5rem;
                }
            }
            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 30px;
                letter-spacing: 0.2px;
                color: #4A5873;
                @media (max-width:767px){
                    font-size: 1rem;
                }
            }
        }
    }
}
.landingContent{
    width:100%;
    margin-bottom: 128px;
   
    @media (max-width:767px){
        display: flex;
        flex-direction: column-reverse;
        gap:2rem;

        margin-bottom: 6rem;

    }
    @media (max-width:550px){
        margin-bottom: 3rem;
    }
    & .landingContent-desc{
        
        & h1{
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #111928;
            margin-top: 32px;
            margin-bottom: 16px;
            @media (max-width:550px){
                font-size: 1.5rem;
            }
        }
        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.2px;
            color: #4A5873;
            @media (max-width:550px){
                font-size: 1rem;
            }
        }
    }
}
.main-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 143px;
    @media (max-width:767px){
        flex-direction: column;
        margin-top: 6rem;
    }
    @media (max-width:550px){
        margin-top: 3rem;
    }
    .main-content-left, .main-content-right{
        width: 42%;     
        
        @media (max-width:767px){
            width: 100%;
        }
    }
    .main-content-right{
        padding-top: 241px;
        @media (max-width:767px){
            padding-top: 0;
        }
    }
}

.landing-showcase{
    background: url(../../assets/showcaseBg.svg)no-repeat center center/cover;
    width: 100%;
    display: flex;
    margin-top: 5px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    
 
    & .landing-showcase-container{
        margin-top:69px ;
        margin-bottom: 99px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 94px;
        margin-right: 86px;
        width: 100%;

        @media (max-width:1419px){
            margin-left: 0;
            margin-right: 0;
            padding:0 2rem 0 2rem;
        }

        @media (max-width:1025px){
            flex-direction: column;
            margin-top: 0;
            gap:2.5rem;
        }
        @media (max-width:461px){
            margin-bottom: 2rem;
        }

        & .landing-showcase-content{
            width: 50%;
            margin-top: 80px;
            position: relative;
            @media (max-width:1025px){
                width: 100%;
                
            }
            & h1{
                font-style: normal;
                font-weight: 700;
                font-size: 64px;
                line-height: 82px;
                letter-spacing: -0.05em;
                color: #151F32;
                margin-bottom: 56px;
                @media (max-width:1269px){
                    font-size: 3rem;
                }
                @media (max-width:528px){
                    font-size: 2.5rem;
                    line-height: 50px;
                }
                @media (max-width:461px){
                    font-size: 2rem;

                    margin-bottom: 2rem;
                }
               

                & .money-text{
                    color:#2EBDB6
                }
            }

            & .money-ribbon{
                position: absolute;
                top:60px;
                right:0px;
                
                @media (max-width:1424px){
                    display: none;
                }
            }
    
            & .landing-showcase-img{
                display: flex;
                justify-content: flex-start;
                gap:15px;
                
                @media (max-width:460px){
                    width:100px;
                }
                @media (max-width:460px){
                    flex-direction: column;
                    width: 150px;
                }

            }
        }
    }

    // & .showcase-img{
    //     margin-top: 115px;
    //     margin-left: 134.48px;
    // }
}

.testimonial-main{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 143px;
    @media(max-width:900px){
        flex-direction: column-reverse;
        gap:2rem;
        margin-top:3rem;
    }
    & .testimonial-left{
        width:49%; 
        height: 400px; 
        overflow-y: hidden;
        position: relative;
        @media(max-width:900px){
            width: 100%;
        }
    }

    & .testimonial-right{
        width:41%;
        @media(max-width:900px){
            width: 100%;
        }
        & h1{
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 46px;
            color: #111928;

            @media(max-width:614px){
                font-size: 1.5rem;
            }
            
        }

        & p{
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.2px;
            color: #4A5873;
            
            @media (max-width:520px){
                font-size: 0.8rem;
            }
        }

        & .testimonial-num{
            margin-top: 32px;
            display: flex;
            justify-content: flex-start;    
            gap: 39px; 
            @media (max-width:520px){
                gap:1rem;
            } 
            
            @media (max-width:480px){
                .num-section {
                    .num-section-title{
                        font-size: 1rem;
                    }
                    .num-section-desc{
                        font-size: 0.6rem;
                    }
                }
            }
           
        }
    }
}

.testimonial{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7F7F7;
    margin-bottom: 14px;
    padding: 33px 0px 33px 14px;
    @media (max-width:550px){
        padding:1rem 0;

    }
   & .testimonial-container{
        width:79%;
        display: flex;
        align-items: flex-start;
        @media (max-width:660px){
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @media (max-width:550px){
            flex-direction: column;
            gap: 1rem;    
        }
        & img{
            width: 45px;
            height: 45px;
            border-radius: 50%;
        }
        & .testimonial-content{
            margin-left: 14px;
            & h1{
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #111928;

                & span{

                }
            }
            & p{
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.2px;
                color: #4A5873;
            }
        }
    }
    
}